import { v4 } from 'uuid';

const userIdKey = 'user-id';

export const getUserId = () => {
  let userId = localStorage.getItem(userIdKey);
  if (!userId) {
    userId = v4();
    localStorage.setItem(userIdKey, userId);
  }
  return userId;
};
