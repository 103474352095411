import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { ThemeName, getThemeDefaultColors } from 'components/Themes';
import {
  selectBookingWidgetTheme,
  selectBookingWidgetPrimaryColor,
  selectBookingWidgetSecondaryColor,
  selectBookingWidgetBackgroundColor,
  selectBookingWidgetSubheaderColor,
  selectBookingWidgetHeaderBackgroundColor,
  selectBookingWidgetFooterBackgroundColor,
  selectBookingWidgetContentSectionBackgroundColor,
} from 'lib/util/bookingWidgetDesign';

interface ThemeParams {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  subheaderColor: string;
  headerBackgroundColor: string;
  footerBackgroundColor: string;
  contentSectionBackgroundColor: string;
  footerTextColor: string;
  usesColorCustomization: string;
  disabledTextColor: string;
  textColor: string;
  themeName: ThemeName;
}

export const useThemeParams = () => {
  const router = useRouter();

  const primaryColorFromSettings = useSelector(selectBookingWidgetPrimaryColor);
  const secondaryColorFromSettings = useSelector(selectBookingWidgetSecondaryColor);
  const backgroundColorFromSettings = useSelector(selectBookingWidgetBackgroundColor);
  const subheaderColorFromSettings = useSelector(selectBookingWidgetSubheaderColor);
  const headerBackgroundColorFromSettings = useSelector(selectBookingWidgetHeaderBackgroundColor);
  const footerBackgroundColorFromSettings = useSelector(selectBookingWidgetFooterBackgroundColor);
  const contentSectionBackgroundColorFromSettings = useSelector(
    selectBookingWidgetContentSectionBackgroundColor
  );

  const themeFromSettings = useSelector(selectBookingWidgetTheme);
  const bookingWidgetTheme = ((router.query.thm as string) ||
    themeFromSettings ||
    'DEFAULT') as ThemeName;

  const themeDefaultColors = getThemeDefaultColors(bookingWidgetTheme);
  const shouldUseThemeDefaultColors = router.query.defCol === 'true';

  const primaryColor = shouldUseThemeDefaultColors
    ? themeDefaultColors.primaryColor
    : (router.query.primary as string) ||
      primaryColorFromSettings ||
      themeDefaultColors.primaryColor;
  const secondaryColor = shouldUseThemeDefaultColors
    ? themeDefaultColors.secondaryColor
    : (router.query.secondary as string) ||
      secondaryColorFromSettings ||
      themeDefaultColors.secondaryColor;
  const backgroundColor = shouldUseThemeDefaultColors
    ? themeDefaultColors.backgroundColor
    : (router.query.bg as string) ||
      backgroundColorFromSettings ||
      themeDefaultColors.backgroundColor;
  const subheaderColor = shouldUseThemeDefaultColors
    ? themeDefaultColors.subheaderColor
    : (router.query.sh as string) ||
      subheaderColorFromSettings ||
      themeDefaultColors.subheaderColor;
  const headerBackgroundColor = shouldUseThemeDefaultColors
    ? themeDefaultColors.headerBackgroundColor
    : (router.query.hbg as string) ||
      headerBackgroundColorFromSettings ||
      themeDefaultColors.headerBackgroundColor;
  const footerBackgroundColor = shouldUseThemeDefaultColors
    ? themeDefaultColors.footerBackgroundColor
    : (router.query.fbg as string) ||
      footerBackgroundColorFromSettings ||
      themeDefaultColors.footerBackgroundColor;
  const contentSectionBackgroundColor = shouldUseThemeDefaultColors
    ? themeDefaultColors.contentSectionBackgroundColor
    : (router.query.cbg as string) ||
      contentSectionBackgroundColorFromSettings ||
      themeDefaultColors.contentSectionBackgroundColor;
  const footerTextColor = themeDefaultColors.footerTextColor;

  const usesColorCustomization =
    primaryColor !== themeDefaultColors.primaryColor ||
    secondaryColor !== themeDefaultColors.secondaryColor ||
    backgroundColor !== themeDefaultColors.backgroundColor ||
    subheaderColor !== themeDefaultColors.subheaderColor ||
    headerBackgroundColor !== themeDefaultColors.headerBackgroundColor ||
    footerBackgroundColor !== themeDefaultColors.footerBackgroundColor ||
    contentSectionBackgroundColor !== themeDefaultColors.contentSectionBackgroundColor;

  const hasCustomContentSectionBackgroundColor =
    contentSectionBackgroundColor.toLowerCase() !== '#fff' &&
    contentSectionBackgroundColor.toLowerCase() !== '#ffffff';
  const disabledTextColor = hasCustomContentSectionBackgroundColor ? '#000' : '#aaa';
  const textColor = !usesColorCustomization ? themeDefaultColors.textColor : '#000';

  return {
    primaryColor,
    secondaryColor,
    backgroundColor,
    subheaderColor,
    headerBackgroundColor,
    footerBackgroundColor,
    contentSectionBackgroundColor,
    footerTextColor,
    usesColorCustomization,
    disabledTextColor,
    textColor,
    themeName: bookingWidgetTheme,
  };
};
