// Selectors

import { createSelector } from 'reselect';

import { ReduxState } from 'ducks';
import { selectBookmarkedProductIds } from 'ducks/client/bookmarks';
import { selectVisitedProductIds } from 'ducks/client/visitedProducts';
import { selectProductSummaries, ProductSummaryShape } from 'lib/util/productSummaryShape';

export const selectTopPageSections = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace?.top_page?.sections,
  (sections) => sections || []
);

const selectProductsById = createSelector(selectProductSummaries, (productSummaries) => {
  const productsById: { [id: string]: ProductSummaryShape } = {};
  for (const product of productSummaries) {
    productsById[product.id] = product;
  }

  return productsById;
});

export const selectIsStandardPlanActive = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (state: ReduxState) => state.server.settings.all.subscriptions ?? [],
  (privateMarketplace, subscriptions) =>
    Boolean(privateMarketplace?.domain) &&
    !subscriptions.some(
      (subscription) => subscription.key === 'standard-plan' && subscription.status === 'CANCELED'
    )
);

export const selectIsPMP = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplace) => Boolean(privateMarketplace?.domain)
);

export const selectShowAddOnRecommendations = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplace) => Boolean(privateMarketplace?.show_add_on_recommendations_during_checkout)
);

export const selectShowTotalBooked = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplace) => Boolean(privateMarketplace?.show_total_booked)
);

export const selectLimitedAvailabilityThreshold = (state: ReduxState): number =>
  state.server.settings.all.limited_availability_threshold ?? 0;

export const selectPopularProductIds = createSelector(
  (state: ReduxState) => state.server.productSummaries.all,
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  selectTopPageSections,
  (products, privateMarketplaceSettings, sections) => {
    if (!sections.includes('POPULAR_PRODUCTS')) {
      return [];
    }

    switch (privateMarketplaceSettings?.ranking_page?.ranking_method) {
      case 'AUTO_TOTAL_BOOKED_30DAY':
        return products
          .filter((p) => Boolean(p.total_booked30_day))
          .sort((p1, p2) => p2.total_booked30_day - p1.total_booked30_day)
          .slice(0, 10)
          .map((p) => p.id);
      case 'AUTO_TOTAL_BOOKED_90DAY':
        return products
          .filter((p) => Boolean(p.total_booked90_day))
          .sort((p1, p2) => p2.total_booked90_day - p1.total_booked90_day)
          .slice(0, 10)
          .map((p) => p.id);
      case 'AUTO_TOTAL_BOOKED_180DAY':
        return products
          .filter((p) => Boolean(p.total_booked180_day))
          .sort((p1, p2) => p2.total_booked180_day - p1.total_booked180_day)
          .slice(0, 10)
          .map((p) => p.id);
      case 'AUTO_GROSS_30DAY':
        return products
          .filter((p) => Boolean(p.gross30_day))
          .sort((p1, p2) => p2.gross30_day - p1.gross30_day)
          .slice(0, 10)
          .map((p) => p.id);
      case 'AUTO_GROSS_90DAY':
        return products
          .filter((p) => Boolean(p.gross90_day))
          .sort((p1, p2) => p2.gross90_day - p1.gross90_day)
          .slice(0, 10)
          .map((p) => p.id);
      case 'AUTO_GROSS_180DAY':
        return products
          .filter((p) => Boolean(p.gross180_day))
          .sort((p1, p2) => p2.gross180_day - p1.gross180_day)
          .slice(0, 10)
          .map((p) => p.id);
      default:
        return privateMarketplaceSettings?.ranking_page?.manually_ranked_product_ids || [];
    }
  }
);

export const selectRecommendedProductIds = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  selectTopPageSections,
  (privateMarketplaceSettings, sections) =>
    (sections.includes('RECOMMENDED_PRODUCTS')
      ? privateMarketplaceSettings?.recommended_products_page?.recommended_product_ids
      : []) ?? []
);

export const selectRecommendedPageDescription = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplaceSettings) =>
    privateMarketplaceSettings?.recommended_products_page?.description || ''
);

export const selectPopularProductSummaries = createSelector(
  selectPopularProductIds,
  selectProductsById,
  (popularProductIds, productsById) =>
    popularProductIds
      .map((productId) => productsById[productId])
      .filter((product) => Boolean(product))
);

export const selectPopularPageDescription = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplaceSettings) => privateMarketplaceSettings?.ranking_page?.description || ''
);

export const selectRecommendedProductSummaries = createSelector(
  selectRecommendedProductIds,
  selectProductsById,
  (recommendedProductIds, productsById) =>
    recommendedProductIds
      .map((productId) => productsById[productId])
      .filter((product) => Boolean(product))
);

export const selectBookmarkedProductSummaries = createSelector(
  selectBookmarkedProductIds,
  selectProductsById,
  (bookmarkedProductIds, productsById) =>
    bookmarkedProductIds
      .map((productId) => productsById[productId])
      .filter((product) => Boolean(product))
);

export const selectVisitedProductSummaries = createSelector(
  selectVisitedProductIds,
  selectProductsById,
  (visitedProductIds, productsById) =>
    visitedProductIds
      .map((productId) => productsById[productId])
      .filter((product) => Boolean(product))
);

export const selectCategories = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace?.category_pages,
  (categoryPages) => categoryPages || []
);

export const selectFeatures = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace?.feature_pages,
  (featurePages) => featurePages || []
);

export const selectTopPage = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace?.top_page,
  (topPage) => topPage
);

export const selectTopPageBannerImageUrl = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace?.top_page?.banner_image_url,
  (banner_image_url) => banner_image_url || ''
);

export const selectHeaderDescriptionText = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace?.header_description_text,
  (headerText) => headerText || ''
);

export const selectExternalLinks = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace?.external_links,
  (externalLinks) => externalLinks || []
);

export const selectUpSellEnabled = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplace) => Boolean(privateMarketplace?.up_sell_enabled)
);

export const selectCrossSellEnabled = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplace) => Boolean(privateMarketplace?.cross_sell_enabled)
);

export const selectProductUpgradeHeaderText = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplace) => privateMarketplace?.product_upgrade_header_text
);

export const selectAddOnUpgradeHeaderText = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplace) => privateMarketplace?.add_on_upgrade_header_text
);

export const selectProductCrossSellHeaderText = createSelector(
  (state: ReduxState) => state.server.settings.all.private_marketplace,
  (privateMarketplace) => privateMarketplace?.cross_sell_similar_product_header_text
);
